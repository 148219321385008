.labelStyle {
	background-color: var(--layout-bg-color);
	border: 1px dashed hsl(from var(--primary-color) h s calc(l + 21));
	box-shadow: inset 0 0 0 2px hsl(from var(--layout-bg-color) h s calc(l + 21));
	min-height: 200px;
	transition: all 200ms ease;
	cursor: pointer;
	border-radius: 12px;
}

.labelStyle:hover {
	border: 1px solid var(--primary-color);
	box-shadow: inset 0 0 0 2px var(--primary-color);
	background-color: var(--layout-bg-color);
}

.fileUploader {
	//background-color: lighten(#FEF8F6, 21%);
	min-height: 200px;
	transition: all 200ms ease;
}

.fileUploader.active {
	cursor: pointer;
}

.fileUploader h4 {
	overflow-wrap: break-word;
	overflow: hidden;
	max-width: 100%;
}

.exists {
	border: 1px solid var(--success-color);
	box-shadow: inset 0 0 0 2px var(--success-color);
}

.uploadButton {
	min-height: 0 !important;
}
