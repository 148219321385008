.pageBody {
  width: 100%;
  height: 100%;
  padding: 15px 2%;
}

.pageContent {
  background-color: var(--page-layout-bg-color);
  border-radius: 24px;
  padding: 24px 40px;
  margin-top: -60px;
  min-height: 300px;
}

.back {
  color: var(--muted-text-secondary);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 35px;
  cursor: pointer;
}
