.logoColor1 {
	fill: var(--primary-color);
	composes: theme-logo__color1 from global;
}

.logoColor2 {
	fill: hsl(from var(--primary-color) h s calc(l + 10));
	composes: theme-logo__color2 from global;
}

.rotate180 {
	transform: rotate(180deg);
}
