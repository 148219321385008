.balance {
  color: var(--header-text-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.pp {
  border-radius: 8px;
  padding: 5px 7px;
  text-align: center;
  display: flex;
  width: 46px;
  height: 46px;
  align-items: center;
}
