.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.content {
	overflow: hidden;
	flex-grow: 1;
	background-color: var(--body-bg-color);
}
