.loading {
	position: relative;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	flex-direction: column;
	overflow: hidden;
}

.content {
	position: absolute;
	left: 0;
	top: 0;
}

.logo {
	animation: step-rotate 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}

.animate1s {
	animation: rotate 1s linear infinite;
}

.animate2s {
	animation: rotate 2s linear infinite;
}

.animate3s {
	animation: rotate 3s linear infinite;
}

.path {
	stroke: var(--primary-color);
	stroke-dashoffset: 0;
	stroke-linecap: round;
	composes: theme-loader from global;
}

.logoPart {
	margin: 0 1px;
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes step-rotate {
	25% {
		transform: rotate(180deg);
	}
	50% {
		transform: rotate(180deg);
	}
	75% {
		transform: rotate(360deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.progress {
	color: var(--primary-color-dark);
}

.okWrapper {
	position: relative;
	transform: rotate(-45deg);
}

.okLeft {
	position: absolute;
	left: 0;
	top: 0;
	background-color: var(--success-color);
	height: 100%;

	animation-duration: 800ms;
	animation-timing-function: ease;
	animation-name: checkMarkLeft;
}

.okBottom {
	position: absolute;
	left: 0;
	bottom: 0;
	background-color: var(--success-color);
	width: 100%;

	animation-duration: 800ms;
	animation-timing-function: ease;
	animation-name: checkMarkBottom;
}

@keyframes checkMarkLeft {
	0% {
		height: 0;
	}
	50% {
		height: 100%;
	}
	100% {
		height: 100%;
	}
}

@keyframes checkMarkBottom {
	0% {
		width: 0;
	}
	50% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
